define([
  'jquery',
  'modules/common/behaviors/behavior',
  'backbone',
  'modules/common/components/string',
  'modules/common/components/moment',
  'backbone.modelbinder',
],
($, Behavior, Backbone, String, Moment) => Behavior.extend({
  defaults: {
    model: false,
    silent: true,
    method: false,
    bindings: {},
    changeTriggers: {
      '': 'change focusout',
    },
  },

  initialize() {
    this.modelBinder = new Backbone.ModelBinder();
    this.modelBinder._behavior = this;
    this.modelBinder._setElValue = this._setElValue;

    this.view.modelBinders = this.view.modelBinders || [];
    this.view.modelBinders.push(this.modelBinder);
  },

  /**
             * Add support for select2 way of setting values
             * @param el
             * @param convertedValue
             * @private
             */
  _setElValue(el, convertedValue) {
    if (el.data('select2') !== undefined && el.find('option').length > 0) {
      el.data('select2').val(convertedValue || (convertedValue === 0 ? '0' : ''));
    } else {
      Backbone.ModelBinder.prototype._setElValue.call(this, el, convertedValue);
    }
  },

  close() {
    this.modelBinder.unbind();

    // Check if a custom model is set
    if (this.options.model) {
      var { model } = this.options;
    }
    // Otherwise fallback to the views model
    else {
      var { model } = this.view;
    }

    delete model.binder;
  },

  convert(direction, value, attributeName, model, elements) {
    const { mapping } = model;
    const element = $(elements[0]);
    const data = element.data();

    if (!(this.options.method in mapping)) {
      throw new Error(`No mapping for: ${
        this.view.model.module}::${
        this.view.model.object}::${
        this.options.method}`);
    }
    const fields = mapping[this.options.method];

    if (fields[attributeName]) {
      const { type } = fields[attributeName];

      if (direction == 'ModelToView' && model.has(attributeName)) {
        value = model.get(attributeName);
      } else if (direction == 'ModelToView' && fields[attributeName].default !== undefined && !model.has(attributeName)) {
        value = fields[attributeName].default;
      }

      switch (type) {
        case 'bool':
          // false
          if ($.inArray(value, ['false', 'no', 'n', '0', 'off', 'f', false, 0, 'jak', 'impossibru']) !== -1) {
            if (direction == 'ModelToView') {
              // select
              if (element.is('select')) {
                value = '0';
              }
              // checkbox / radio button
              else {
                value = false;
              }
            } else {
              value = '0';
            }
          }
          // true
          else if ($.inArray(value, ['true', 'yes', 'y', '1', 'on', 't', true, 1, 'hoe', 'bazinga']) !== -1) {
            if (direction == 'ModelToView') {
              // select
              if (element.is('select')) {
                value = '1';
              }
              // checkbox / radio button
              else {
                value = true;
              }
            } else {
              value = '1';
            }
          }
          // if we got here, value is undefined
          // default we will return false, except when we use select2
          // then we first will check if there is a placeholder and just select that one
          else if (direction == 'ModelToView') {
            if (element.data('select2') !== undefined && element.data('placeholder')) {
              // we just return the original value so select2 can handle the rest
            } else if (element.is('select')) {
              value = '0';
            } else {
              value = false;
            }
          } else {
            value = '0';
          }
          break;
        case 'text':
          value = String(value);
          if (value === null || value === undefined || value === 'undefined') {
            value = '';
          }
          break;
        case 'float':
          if (value !== undefined) {
            if (data.type == 'currency') {
              if (direction == 'ModelToView') {
                value = String.toCurrency(value);
              } else {
                value = String.toFloat(value);
              }
            } else if (value !== undefined && value.length > 0) {
              value = value.replace(',', '.');
              value = parseFloat(value);
              if (isNaN(value)) {
                value = 0;
              }
            }
          }
          break;
        case 'int':
          if (value !== undefined && value !== null && value.length > 0) {
            value = parseInt(value);
            if (isNaN(value)) {
              value = 0;
            }
          }
          break;
        case 'date':
          if (value !== undefined && value.length > 0) {
            if (direction == 'ModelToView') {
              value = Moment(value).locale('nl').format('DD-MM-YYYY');
            } else {
              value = Moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD HH:mm:ssZ');
            }
          }
          break;
        case 'datetime':
          if (value !== undefined && value.length > 0) {
            if (direction == 'ModelToView') {
              value = Moment(value).locale('nl').format('DD-MM-YYYY HH:mm');
            } else {
              value = Moment(value, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ssZ');
            }
          }
          break;
        default:
          value = value;
      }
    } else {
      if (direction == 'ModelToView') {
        return value;
      }
      return element.val();
    }

    return value;
  },

  bind() {
    // Reference to ourself
    const self = this;
    let { el } = this.view;

    if (this.options.el !== undefined) {
      el = this.view.$el.find(this.options.el).get(0);
    }

    // When no custom bindings are set
    if ($.isEmptyObject(this.options.bindings)) {
      // Get the default bindings
      var bindings = Backbone.ModelBinder.createDefaultBindings(el, 'name');

      // Set the default converter
      $.each(bindings, function () {
        this.converter = function (direction, value, attributeName, model, elements) {
          return self.convert(direction, value, attributeName, model, elements);
        };
      });
    }
    // When there are bindings
    else {
      // Custom bindings
      const customBindings = {};

      // Get the custom bindings
      var { bindings } = this.options;

      // When no custom converter is set, fallback to the default one
      $.each(bindings, function (key, binding) {
        if (!binding.converter) {
          customBindings[key] = {
            selector: binding,
            converter(direction, value, attributeName, model, elements) {
              return self.convert(direction, value, attributeName, model, elements);
            },
          };
        } else {
          customBindings[key] = this;
        }
      });

      // Set the bindings
      bindings = customBindings;
    }

    // Check if a custom model is set
    if (this.options.model) {
      var { model } = this.options;
    }
    // Otherwise fallback to the views model
    else {
      var { model } = this.view;
    }

    const bindOptions = {
      modelSetOptions: {
        silent: this.options.silent,
        validate: true,
      },
      changeTriggers: this.options.changeTriggers,
    };

    // Bind to the model set on the view
    this.modelBinder.bind(model, el, bindings, bindOptions);

    // Save modelbinder to model
    model.binder = this.modelBinder;
    model.rebind = function () {
      self.bind(model, el, bindings, bindOptions);
    };
  },

  onRender() {
    this.bind();
  },
}));
