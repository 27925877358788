define([
  'jquery',
  'application',
  'modules/common/components/component',
],
($, App, Component) => {
  const struct = Component.extend({
    read(module, object) {
      let struct = {};

      $.ajax({
        url: `static/structs/${module}/${object}.json`,
        type: 'GET',
        dataType: 'json',
        async: false,
        success(response) {
          struct = response;
        },
      });

      return struct;
    },
  });

  return new struct();
});
