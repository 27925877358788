define([
  'jquery',
],
($) => {
  const UPX = function () {
    /*
         * Local variables
         * @private
         */
    let _server = null;
    let _account = null;
    let _user = null;
    let _subaccount = null;
    let _password = null;
    let _apikey = null;
    let _hash = null;
    let _mode = null;
    let _rights = 'user';
    let _clientName = 'anonymous';
    let _xdebug = false;

    /*
         * Serialize the object
         * @private
         * @var obj The object to serialize
         * @var prefix Any prefix we need to prepend
         * @returns string
         */
    var _serialize = function (obj, prefix) {
      const str = [];
      for (const p in obj) {
        const k = prefix ? `${prefix}[${p}]` : p;
        const v = obj[p];
        str.push(typeof v === 'object'
          ? _serialize(v, k)
          : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
      }
      return str.join('&');
    };

    /*
         * Prepare the authentication object
         * @private
         * @returns object
         * @throws Error
         */
    const _prepareAuth = function () {
      const auth = {};

      if (_account === null) {
        throw new Error('Account should be set.');
      } else {
        auth.account = _account;
      }

      if (_mode === null) {
        throw new Error('Password, Hash, Apikey or Anonymous should be set.');
      } else {
        auth.mode = _mode;
      }

      if (_rights === null) {
        throw new Error('User or subuser should be set.');
      } else {
        auth.rights = _rights;
      }

      if (_rights == 'subuser') {
        auth.subaccount = _subaccount;
        auth.user = _user;
      } else {
        auth.user = _user;
      }

      if (_mode == 'password') {
        auth.password = _password;
      } else if (_mode == 'apikey') {
        auth.apikey = _apikey;
      } else if (_mode == 'hash') {
        auth.hash = _hash;
      }

      if (_clientName) {
        auth.client_name = _clientName;
      }

      return auth;
    };

    /*
         * Set the client name
         * @var client type identification
         * @public
         */
    this.setClientName = function (clientName) {
      _clientName = clientName;
    };
    /*
         * Set the server
         * @var server The UPX server
         * @public
         */
    this.setServer = function (server) {
      _server = server;
    };
    /*
         * Set Xdebug string
         * @var Xdebug string for starting session
         * @public
         */
    this.setXDebug = function (sessionName) {
      _xdebug = sessionName;
    };
    /*
         * Set the account
         * @var account The main account
         * @public
         */
    this.setAccount = function (account) {
      _account = account;
    };

    /*
         * Set the username and rights level to user
         * @var user The username
         * @public
         */
    this.setUser = function (user) {
      _user = user;
      _subaccount = null;
      _rights = 'user';
    };

    /*
         * Set the subaccount and rights level to subuser
         * @var subbacount The subbaccount
         * @var subuser The subuser
         * @public
         */
    this.setSubaccount = function (subaccount) {
      _subaccount = subaccount;
      _rights = 'subuser';
    };

    /*
         * Set the mode to anonymous
         * @public
         */
    this.setAnonymous = function () {
      _user = 'anonymous';
      _rights = 'anonymous';
      _subaccount = null;
      _password = null;
      _hash = null;
      _apikey = null;
      _mode = 'none';
    };

    /*
         * Set the password as authentication mode
         * @var password The password
         * @public
         */
    this.setPassword = function (password) {
      _password = password;
      _hash = null;
      _apikey = null;
      _mode = 'password';
    };

    /*
         * Set the hash as authentication mode
         * @var hash The hash
         * @public
         */
    this.setHash = function (hash) {
      _hash = hash;
      _password = null;
      _apikey = null;
      _mode = 'hash';
    };

    /*
         * Set the apikey as authentication method
         * @var apikey The apikey
         * @public
         */
    this.setApikey = function (apikey) {
      _apikey = apikey;
      _password = null;
      _hash = null;
      _mode = 'apikey';
    };

    /*
         * Call the given method on the UPX server
         * @var module The module which contains the method
         * @var method The Method which needs to be executed
         * @var parameters (optional) The parameters which needs to be passed to the method
         * @var ajaxOptions (optional) Options which will be set on the ajax request
         * @public
         * @returns promise object
         * @throws Error
         */
    this.call = function (module, method, parameters, ajaxOptions) {
      if (typeof module === 'undefined') {
        throw new Error('Module is a required parameter.');
      }

      if (typeof method === 'undefined') {
        throw new Error('Method is a required parameter.');
      }

      if (_server == null) {
        throw new Error('Server should be set.');
      }

      let url = `${_server}/?action=request&api=fulljson&module=${module}&function=${method}`;
      if (_xdebug) {
        url += `&XDEBUG_SESSION_START=${_xdebug}`;
      }
      var parameters = parameters || {};
      const auth = _prepareAuth();
      var ajaxOptions = ajaxOptions || {};

      return $.ajax($.extend({}, {
        method: 'POST',
        url,
        timeout: 25000,
        processData: false,
        data: JSON.stringify({
          params: parameters,
          auth,
        }),
        contentType: 'application/json; charset=utf-8',
        dataType: 'json',
      }, ajaxOptions)).then(
        (response) => $.Deferred((deferred) => {
          if (response.success) {
            deferred.resolve(response.response);
          } else {
            deferred.reject(response);
          }
        }).promise(),
        (jqXHR, textStatus, errorThrown) => $.Deferred((deferred) => {
          let error = `network error: ${textStatus}`;
          if (jqXHR.status) {
            error = `HTTP ERROR: ${jqXHR.status}`;
            console.error(`${module}::${method} XHR error: `, textStatus);
          } else {
            // it connectivity issues only warning, otherwise ends up in sentry
            console.warn(`${module}::${method} `, error);
          }
          deferred.reject({
            success: false,
            error,
            xhr: true,
          });
        }).promise(),
      );
    };

    /*
         * Prepares a call wrapped in a function so it can be executed later on
         * @var call The function which contains the call
         * @var onSuccess What to do when the call succeeds
         * @var onError What to do when the call fails
         * @public
         * @returns function
         */
    this.prepareCall = function (call, onSuccess, onError) {
      onSuccess = onSuccess || function () {};
      onError = onError || function () {};

      return function () {
        const promise = call();
        $.when(promise).then(onSuccess, onError);
        return promise;
      };
    };

    /*
         * Prepares a multiple prepared calls wrapped in a function so it can be executed later on
         * @var preparedCalls The prepared calls
         * @var onSuccess What to do when all calls succeeds
         * @var onError What to do when a prepared call fails
         * @public
         * @returns function
         */
    this.multiCall = function (preparedCalls, onSuccess, onError) {
      onSuccess = onSuccess || function () {};
      onError = onError || function () {};

      return function () {
        const promises = [];

        $.each(preparedCalls, function () {
          promises.push(this());
        });

        $.when.apply($, promises).then(onSuccess, onError);
      };
    };
  };

  return UPX;
});
