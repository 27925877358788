define([
  'application',
  'module',
  'modules/common/components/module',
  'modules/common/application',
  'modules/common/components/managers/configuration',
  './crontabs/refresh',
  'modules/upx/collections/users',
],
(App, Module, AppModule, CommonModule, ConfigurationManager,
  RefreshCron, UsersCollection) => {
  const module = new AppModule({
    id: 'modules/upx/application',

    onStart() {
      App.com(ConfigurationManager).get('upx').set(App.settings.upx);

      this.crontabs = {
        refresh: new RefreshCron().start(),
      };
    },
  });

  return module.register();
});
