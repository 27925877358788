define([
  'modules/common/behaviors/behavior',
],
(Behavior) => Behavior.extend({
  defaults: {
    silent: true,
    model: false,
  },

  onRender() {
    this.store();
  },

  store() {
    // Check if a custom model is set
    if (this.options.model) {
      var { model } = this.options;
    }
    // Otherwise fallback to the views model
    else {
      var { model } = this.view;
    }

    if (!model) {
      console.error('Momento has no model found in the view (this.model) or passed to momento as option');
    }
    model.store();
  },

  restore() {
    // Check if a custom model is set
    if (this.options.model) {
      var { model } = this.options;
    }
    // Otherwise fallback to the views model
    else {
      var { model } = this.view;
    }

    model.restore({ silent: this.options.silent });
  },

  close() {
    this.restore();
  },

  onDestroy() {
    this.restore();
  },
}));
