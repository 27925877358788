define([
  'modules/common/crontabs/cron',
  'modules/upx/collections/users',

  'modules/upx/events/session/beforeRefresh',
  'modules/upx/events/session/noRefresh',
  'modules/upx/events/session/successfulRefresh',
  'modules/upx/events/session/failedRefresh',
  'modules/upx/events/session/afterRefresh',
], (
  Cron, UsersCollection,
  BeforeRefreshEvent, NoRefreshEvent, SuccessfulRefreshEvent, FailedRefreshEvent, AfterRefreshEvent,
) => Cron.extend({
  cron: '* * * * *',

  run() {
    UsersCollection.fetch().then(() => {
      const user = UsersCollection.findWhere({
        active: true,
        mode: 'hash',
      });
        // If we found an active user
      if (user !== undefined) {
        // and was working within the last 30 minutes, then refresh
        if (user.shouldRefreshSession()) {
          new BeforeRefreshEvent({ user }).trigger();

          user.refreshSession()
            .then(() => {
              new SuccessfulRefreshEvent({ user }).trigger();
            }, (resp) => {
              const options = {
                user,
                error: resp,
              };
              new FailedRefreshEvent(options).trigger();
            })
            .always(() => {
              new AfterRefreshEvent({ user }).trigger();
            });
        } else {
          new NoRefreshEvent({ user }).trigger();
        }
      }
    });
  },

}));
